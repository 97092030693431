.adult-mobile, .child-mobile {
    display: none;
}

.counter {
    background-color: #b78e48;
    color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.feature-item-three .service-icon {
    filter: drop-shadow(0px 0px 10px #b78e47);
    opacity: 0.8;
}

.feature-item-three:hover .service-icon {
    filter: invert(1);
}

.breadcrumbs-wrapper a:hover {
    color: #aa8453 !important;
}

.hotel-search form .date input[type="text"] {
    width: 300px;
    padding-left: 8px;
    padding-right: 8px;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: #b78e48 !important;
    border-radius: 0;
    margin: 0 !important;
}



@media screen and (max-width: 767px) {
    .header {
        min-height: 73vh;

    }

    .section-padding {
        padding: 40px 0;
    }

    .section-title {
        font-size: 32px;


    }

    .rooms2 .caption h4, .rooms2 .caption h4 a {
        font-size: 28px;

    }

    .img.right, .img.left, .services .img a, .services img, .img.right, .img.left {
        width: 100% !important;
        height: auto !important;
    }

    .header.slider-fade, .header .owl-item, .slider-fade .item {
        min-height: 70vh;
        height: 70vh;
    }

    .slider-fade .owl-dots {
        top: unset;
        bottom: 2%;
    }

    .adult-desktop, .child-desktop {
        display: none;
    }

    .adult-mobile, .child-mobile {
        width: 100%;
        margin: auto;
        text-align: center;
    }

    .adult, .child {
        width: 75%;
    }

    .hotel-search form .form-group input[type="number"] {
        width: 65%;
        text-align: center;
        margin: auto;
    }

    .logo-hotel {
        width: 100px;
        height: 60px;

    }
}

@media screen and (max-width: 767px) {
    .adult-mobile, .child-mobile {
        align-items: center;
        display: flex;
    }
}

.home-icon {
    color: #fff;
}

.nav-tabs .nav-link {
    color: #b78e47;
}

.nav-scroll .navbar-nav .nav-link, .nav-scroll .home-icon {
    color: #555;
}

.banner-header {
    background-position: center;
}

.nav-btn {
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-radius: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.nav-btn:after {
    font-size: 15px;
    position: absolute;

    top: 50%;
    transform: translateY(-50%);
    color: inherit;
}

.nav-btn.prev-slide:after {
    content: "<";
    left: 20%;
}

.nav-btn.next-slide:after {
    content: ">";
    right: 20%;
}

.prev-slide {
    left: 10px;
}

.next-slide {
    right: 10px;
}

.slider-fade .owl-dots .owl-dot span {
    display: none !important;
}

.owl-theme .owl-dots {

    /* transform: rotateZ(90deg); */
}

.owl-theme .owl-dots .owl-dot {
    margin-bottom: 10px;
    display: block !important;
}

.input-group-text {
    border-radius: 0;
    height: 100%;
    border: 1px solid #cecece;
    border-left: 0;
    background-color: transparent !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    padding: 10px 0 !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header, .react-datepicker__day-name {
    color: #fff !important;
    font-weight: 600;
}

.hotel-search form input[type="text"] {
    border-radius: 0;
    border-right: 0;
}

.react-datepicker-popper {
    z-index: 999 !important;
}

.react-datepicker__header, .react-datepicker__day--selected {
    background-color: #b78e48 !important;

}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    fill: #b78e48 !important;
    color: #b78e48 !important;
}

.react-datepicker__navigation:hover *::before, .react-datepicker__navigation-icon::before {
    border-color: #fff !important;
}

/* Rooms  */
#rooms .owl-theme .owl-nav.disabled+.owl-dots {
    display: flex;
    justify-content: center;

}

#rooms .owl-theme .owl-nav.disabled+.owl-dots .owl-dot span {
    background-color: transparent;
    width: 12px;
    height: 12px;
}

#rooms .owl-theme .owl-nav.disabled+.owl-dots .owl-dot.active span {
    background: #b78e48;

}

/* Slider  */
header.header h4, header.header h1 {
    text-shadow: 0 2px 2px #000;
}

/* Hotels  */
.bacground-logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    opacity: 0.03;
    z-index: -1;
    filter: opacity(0.05);
    filter: grayscale(1);

}

/* Dining  */
#dining .owl-theme .owl-dots {
    display: flex;
    justify-content: center;

}

#dining .owl-theme .owl-dots .owl-dot span {
    background-color: transparent;
    width: 12px;
    height: 12px;
}

#dining .owl-theme .owl-dots .owl-dot.active span, #dining .owl-theme .owl-dots .owl-dot:hover span {
    background: #b78e48;

}

/* News  */
#news .owl-theme .owl-nav.disabled+.owl-dots {
    display: flex;
    justify-content: center;

}

#news .owl-theme .owl-nav.disabled+.owl-dots .owl-dot span {
    background-color: transparent;
    width: 12px;
    height: 12px;
}

#news .owl-theme .owl-nav.disabled+.owl-dots .owl-dot.active span {
    background: #b78e48;

}

.section-title.text-dark {
    color: #222 !important;
}

#online input {
    height: 70px;
    border: 1px solid #ececec;
    padding-left: 10px;
    color: #000;
    margin-bottom: 0;
    border-radius: 0;
    width: 100%;
}

#online textarea {

    border: 1px solid #ececec;
    padding-left: 10px;
    color: #000;
    margin-bottom: 0;
    border-radius: 0;
    width: 100%;
}

#online .input-group-text {
    height: 100%;
}

#online .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), #online .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control, #online .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select, #online .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    width: 89%;
}

.contact-box {
    background-color: #fcfcfc;
    padding: 20px;
    box-shadow: 0 0 5px #ccc;
    border-radius: 10px;
}

/* FAQ  */
.accordion.-type-1 .accordion__button {
    cursor: pointer;
    transition: 0.4s;
}

.bg-light-1 {
    background-color: #F8F5F0;
}

.fw-500 {
    font-weight: 500;
}

.lh-1 {
    line-height: 1 !important;
}

.text-24 {
    font-size: 19px !important;
}

.y-gap-10>* {
    padding-top: 5px;
    padding-bottom: 5px;
}

.px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.py-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.justify-between {
    justify-content: space-between !important;
}

.py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.amenitie-wrapper {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
}

.amenities {
    display: flex;
    justify-content: start;
    align-items: center;
}

.amenities-icon {
    width: 20px !important;
    margin-right: 5px;
}

/* Facilities  */
.img.right, .img.left {
    width: 570px;
    height: 380px;
}

.rooms1 .item .con p {
    font-size: 16px;
}

.services p:not(.view-all) {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}



.services .img {
    height: auto;

}

.services .img a {
    height: auto;
    width: 100%;
}

.services img {
    height: 420px;


}

.contactForm select, .contactForm input:not([type="range"]), .contactForm textarea {
    font-size: 17px;
    line-height: 1.4;
    height: 62px;
    background-color: white;
    border: 1px solid #DDDDDD;
    padding: 0 20px;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.contactForm input, .contactForm .react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__day--in-range:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--selected:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__year-text--in-range:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--selected:hover {
    background-color: #b78e47;
}

.contactForm p {
    text-align: left;
}

/* .contactForm button.butn-dark2 {
    color: #fff !important;

} */

/* .contactForm button.butn-dark2:after {
    background-color: #555;

} */

.contactForm .form-input {
    position: relative;
}

#dining p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

#news p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.services .col-md-6 .content {
    padding: 25px 30px;
}

@media screen and (min-width: 768px) {
    .banner-header {
        background-position: center;
        height: 100vh;
        width: 100%;
    }
}

#news .item img {
    width: 365px;
    height: 487px;
}

#news.new .item img {
    width: 365px;
    height: 300px;
}

.news .item img {
    width: 365px;
    height: 487px;
}

.footer-overlay {
    position: absolute;
    top: 0;
    right: 0%;
    background-position: bottom right;
    background-attachment: fixed;
    background-size: 30% 60%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    opacity: 1;
    z-index: 2;

}

@media screen and (max-width: 768px) {
    .footer-overlay {

        background-position: bottom right;
        background-attachment: fixed;
        background-size: 100% 60%;

        width: 100%;
        height: 100%;


    }

    .footer-top .col-md-6:first-child .footer-column, .footer-top .footer-explore-list {
        margin-bottom: 2px;
    }

    .footer-title.second {
        display: none;
    }


    .logo-mobile {
        width: 110px;
    }

    .hotel-search select {
        padding: 0 60px 0 30px;
    }

    .text-24 {
        font-size: 14px !important;
        line-height: 2.4 !important;
    }
}

.details .owl-theme .owl-dots .owl-dot {
    display: inline-block !important;
}

.page-list-icon i {
    color: #b78e47 !important;
    font-weight: 600;
}

/* Spa  */
#spa.rooms1 .item img {
    height: 300px;
}




.footer {
    background-color: #222;
}

.footer-top {
    background-color: transparent;
    z-index: 3;
}

footer .footer-bottom .row {
    position: relative;
    z-index: 10;
}

.starting-from {
    display: flex !important;
    align-items: center !important;
    justify-content: space-around !important;
    max-width: 1000px !important;
    margin: 0 auto !important;
    padding: 1px !important;
    font-size: 13px !important;
    border: 0px solid #000 !important;
}

.starting-from .starting-from--price {
    font-size: 20px !important;
    color: #98222a !important;
}

.show-modal[data-v-05537ca1] {
    cursor: pointer;
    display: inline-block;
    line-height: 20px;
    min-width: 100px;
    padding: 9px 10px !important;
    text-align: center;
    vertical-align: middle;
}

.best-price .day--price {
    color: #008a00 !important;
    font-size: 10px !important;
}

.upload-btn {
    font-size: 17px !important;
    line-height: 1.4 !important;
    height: 62px !important;
    background-color: transparent !important;
    border: 1px solid #DDDDDD !important;
    color: inherit !important;
    padding: 0 20px !important;
    box-shadow: unset !important;
    width: 100% !important;
    transition: all 0.3s
}