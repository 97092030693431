.items-container {
  transition: opacity 0.5s ease-in-out;
}

.hidden {
  opacity: 0;
  pointer-events: none;
  /* Optional: Disable interactions when hidden */
}

.visible {
  opacity: 1;
  pointer-events: auto;
  /* Optional: Enable interactions when visible */
}

.gallery-page a {
  padding: 6px !important;
}

.bg-main:active,
.bg-main:focus {
  background-color: #D9B95E !important;
}

.container-more {
  transition: all 2s ease-in-out !important;
}

.items-container {
  /* You can customize other styles as needed */
  width: 100%;
  max-height: unset;
  overflow: hidden;
}

.esg-center.eg-post-157.eg-playa-element-0.esg-transition {
  overflow: hidden;
  position: relative;
  transition: all .5s ease-in-out;
}

.gallery-page a {
  padding: 6px !important;
}

.scheme_default a {
  color: #4bbed1;
}

.eg-playa-element-0:hover, .eg-playa-element-0:not(.col-lg-3) {
  border: 0 solid #fff !important;
  border-radius: 0 !important;
  color: #fff !important;
  font-size: 58px !important;
  font-weight: 900 !important;
  line-height: 40px !important;
}



.gallery_egplayaelement0a__wydIn {
  align-items: center !important;
  display: flex !important;
  height: 5px !important;
  justify-content: center !important;
  left: 50% !important;
  opacity: 0;
  position: absolute !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  transition: opacity .2s ease-in-out !important;
  width: 5px;
}

.col-3 img {
  height: auto;
  max-width: 100%;
  vertical-align: top;
}

.esg-center.eg-post-157.eg-playa-element-0.esg-transition:after {
  background-color: initial;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all .5s ease-in-out;
  width: 100%;
  z-index: 0;
}